import { Container, Text, Button, Group, Stack } from '@mantine/core';
import feelings from '@/static/images/illustrations/undraw_handcrafts_feelings.svg';
import Link from 'next/link';
import classes from './404.module.css';
export function InternalErrorPage() {
  return (
    <Container fluid className={classes.root}>
      <Stack>
        <img className={classes.image} src={feelings} alt="error" />
        <Text c="dimmed" size="lg" ta="center" className={classes.description}>
          Something seems to have gone wrong. Please try again in a few minutes.
          If the issue persists contact support.
        </Text>
        <Group justify="center">
          <Button
            href="/"
            component={Link}
            color="green"
            variant="outline"
            size="md"
          >
            Take me back home
          </Button>
        </Group>
      </Stack>
    </Container>
  );
}

export default InternalErrorPage;
